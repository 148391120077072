/**
 * Types for subscription & one time sale items
 * @packageDocumentation
 */

export interface SubscriptionPackage {
  price: string;
  yearPrice: string;
  name: string;
  description: string;
  id: string;
  level: number;
  type: 'standard' | 'custom';
}

export const BlankSubscriptionPackage: SubscriptionPackage = {
  price: '',
  yearPrice: '',
  name: '',
  description: '',
  id: '',
  level: 0,
  type: 'standard'
};

export interface ItemListing {
  price: string;
  name: string;
  description: string;
  id: string;
  type: 'standard' | 'custom';
}

export const BlankItemListing: ItemListing = {
  price: '',
  name: '',
  description: '',
  id: '',
  type: 'standard'
};

export interface PromoCode {
  id: string;
  code: string;
  packageId: string;
  discount: number;
  price: string;
  uses: number;
  useLimit: number;
}

export const BlankPromoCode: PromoCode = {
  id: '',
  code: '',
  packageId: '',
  discount: 0,
  price: '',
  uses: 0,
  useLimit: 1000
};
