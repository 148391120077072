/**
 * Contains helper functions for various things, turning a file to base64 string,
 * getting hash of a string, and deduping an arr
 * @packageDocumentation
 */
import { Md5 } from 'ts-md5';

export const GetBase64: (file: File) => Promise<string> = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject('File is not readable.');
      }
    };
    reader.onerror = (error) => reject(error);
  });

export const GetHash: (hashee: string) => string = (hashee) =>
  Md5.hashStr(hashee).toString();

export function DedupeArr<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}
