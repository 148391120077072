/**
 * Types and helper functions for trades & trade lists
 * @packageDocumentation
 */
export interface Trade {
  trade: string;
  category: TradeListCategory;
}

export interface SortedTradeList {
  trades: Trade[];
  sorted: boolean;
  sortedBy: string;
  filterString: string;
}

export interface OptionType {
  label: string;
  value: string;
  trade: Trade;
}

export type TradeList = Record<TradeListCategory, string[]>;
type AllTradeCategories = typeof TradeListCategories;
export type TradeListCategory = AllTradeCategories[number];

export function createTradeList(rawTrades: TradeList): SortedTradeList {
  const list: SortedTradeList = {
    trades: [],
    sorted: false,
    sortedBy: '',
    filterString: ''
  };

  const categories = Object.keys(rawTrades) as TradeListCategory[];

  categories.forEach((key) => {
    if (rawTrades[key].length > 0) {
      rawTrades[key].forEach((trade) => {
        list.trades.push({
          trade,
          category: key
        });
      });
    }
  });
  return list;
}

export function sortTradeList(TL: Trade[]): Trade[] {
  return TL.sort((a, b) => {
    if (a.trade < b.trade) {
      return -1;
    } else if (a.trade > b.trade) {
      return 1;
    } else {
      return 0;
    }
  });
}

export const GetTradeListOptions: (tradeList: Trade[]) => OptionType[] = (
  tradeList
) =>
  tradeList.map((trade) => ({
    label: trade.trade,
    value: trade.trade,
    trade
  }));

export const TradeListCategories = [
  'Ceiling Wall',
  'Cleaning',
  'Communications',
  'Conveying',
  'Demolition',
  'Detection Alarm',
  'Earthwork',
  'Electric',
  'Equipment',
  'Exterior Enclosure',
  'Floors Stairs',
  'Foundation Substructure',
  'Furnishings',
  'Garbage',
  'Glass',
  'HVAC',
  'Insulation',
  'Interior Construction',
  'Labor',
  'Landscape',
  'Masonry',
  'Material Moving Handling',
  'Paint',
  'Plumbing',
  'Popular',
  'Recreational',
  'Remediation',
  'Restoration',
  'Roadway',
  'Roof',
  'Scaffolding',
  'Sheet Metal',
  'Siding',
  'Site Prep',
  'Special Construction',
  'Special Interior',
  'Structure',
  'Substructure Mitigation',
  'Supplier',
  'Utilities',
  'Welding'
] as const;
