import { PaymentMethod } from './Forte';

/**
 * Types and helper functions for forte transactions
 * @packageDocumentation
 */
export type TransactionMethod = 'sale' | 'schedule' | 'token' | 'auth';

/**
 * @interface Transaction
 * id is passed to forte as reference_id
 * consumerId === user id
 */
export interface Transaction {
  method: TransactionMethod;
  versionNumber: '2.0';
  totalAmount: string;
  consumerId: string;
  orderNumber: string;
  customerToken: string;
  payMethodToken: string;
  enteredBy: string;
  utcTime: string;
  id: string;
  description: string;
}

export const BlankTransaction: Transaction = {
  method: 'sale',
  versionNumber: '2.0',
  totalAmount: '',
  consumerId: '',
  orderNumber: '',
  customerToken: '',
  payMethodToken: '',
  enteredBy: '',
  utcTime: '',
  id: '',
  description: ''
};

export type ScheduleFrequency =
  | 'weekly'
  | 'bi-weekly'
  | 'monthly'
  | 'bi-monthly'
  | 'quarterly'
  | 'semi-annually'
  | 'annually';

export interface ScheduledTransaction extends Transaction {
  method: 'schedule';
  scheduled: true;
  scheduleStartDate: string;
  scheduleFrequency: ScheduleFrequency;
}

export const BlankScheduledTransaction: ScheduledTransaction = {
  ...BlankTransaction,
  method: 'schedule',
  scheduled: true,
  scheduleStartDate: '',
  scheduleFrequency: 'monthly'
};

export interface ConfirmedTransaction extends Transaction {
  signature: string;
  traceNumber: string;
  authorizationCode: string;
  requestId: string;
  methodUsed: PaymentMethod;
}

export const BlankConfirmedTransaction: ConfirmedTransaction = {
  ...BlankTransaction,
  signature: '',
  traceNumber: '',
  authorizationCode: '',
  requestId: '',
  methodUsed: 'visa'
};

export type ConfirmedScheduledTransaction = ScheduledTransaction &
  ConfirmedTransaction;

export const BlankConfirmedScheduledTransaction: ConfirmedScheduledTransaction = {
  ...BlankScheduledTransaction,
  signature: '',
  traceNumber: '',
  authorizationCode: '',
  requestId: '',
  methodUsed: 'visa'
};
