/**
 * Contains typings for bids
 * @packageDocumentation
 */
import { Trade } from './Trade';

export interface Bid {
  firstName: string;
  lastName: string;
  email: string;
  phoneNum: string;
  companyName: string;
  materialIncluded: boolean;
  bidAmt: number;
  trades: Trade[];
  message: string;
  createdAt: number;
  lastUpdated: number;
}

export const DefaultNewBid: Bid = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNum: '',
  companyName: '',
  materialIncluded: false,
  bidAmt: 0,
  trades: [],
  message: '',
  createdAt: 1559252406785,
  lastUpdated: 1559252406785
};

export interface FullBid extends Bid {
  id: string;
  projectId: string;
  projectOwner: string;
  projectName: string;
  ownerId: string;
  approved: boolean;
}

export const DefaultBid: FullBid = {
  id: '',
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phoneNum: '',
  materialIncluded: false,
  bidAmt: 0,
  trades: [],
  message: '',
  projectId: '',
  projectOwner: '',
  projectName: '',
  ownerId: '',
  approved: false,
  createdAt: 1559252406785,
  lastUpdated: 1559252406785
};
