/**
 * Types for users, document urls, subscription status
 * @packageDocumentation
 */
import { ConstructionType, PhotoUrl, SupportedLang } from './Project';
import { Trade } from './Trade';

export interface StreetAddress {
  full: string;
  city: string;
  state: string;
  streetAddr: string;
  streetAddrTwo: string;
  country: string;
  zipCode: string;
}

export const BlankStreetAddress: StreetAddress = {
  full: '',
  city: '',
  state: '',
  streetAddr: '',
  streetAddrTwo: '',
  country: '',
  zipCode: ''
};

interface DocumentUrl extends PhotoUrl {
  lastUpdated: number;
  name: string;
}

export const BlankDocumentUrl: DocumentUrl = {
  original: '',
  processed: '',
  hash: '',
  height: 0,
  width: 0,
  lastUpdated: 0,
  name: ''
};

/**
 * SubscriptionStatus enum
 * Late is for grace period (i.e. 7 days to make payment before access is reduced)
 * Past late means reduced access to none level
 * Unclaimed is for accounts that have been setup for someone, should get free trial
 * when they claim it (planned future, put in for now bc of how enums work)
 * Paid profile means they just pay the subscription for a public profile
 * Paid all means they pay for everything
 * Pay per lead may not be used, in discussion w/ Stefan talked about allowing ppl to pay per lead
 * (i.e. pay just to say interested on a project) like homeadvisor et al., with option to upgrade
 * to subscription whenever they would have to do a lead payment
 * Since these go sequentially (i.e. None = 0, Unclaimed = 1, etc.) cannot add to it in middle,
 * must add new ones at the end (and shouldn't remove any of these)
 */
export enum SubscriptionStatus {
  None,
  Unclaimed,
  FreeTrial,
  Comped,
  Late,
  PastLate,
  Banned,
  PaidProfile,
  PaidAll,
  PayPerLead
}

export interface CardPaymethodToken {
  name: string;
  last4: string;
  expireMonth: string;
  expireYear: string;
  token: string;
}

export interface UserData {
  fullName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  role: 'sub' | 'gen';
  country: string;
  email: string;
  phoneNum: string;
  phoneNumTwo: string;
  faxNum: string;
  companyPhoneNum: string;
  companyName: string;
  companyWebAddr: string;
  officeHours: string;
  linkedin: string;
  facebook: string;
  twitter: string;
  mailAddr: string;
  billingAddress: StreetAddress;
  language: SupportedLang;
  trades: Trade[];
  preferredWork: ConstructionType[];
  bilingual: boolean;
  referralLink: string;
  profileLink: string;
  referrer: string;
  notes: string;
  logo: PhotoUrl;
  avatar: PhotoUrl;
  companyDocs: { [key: string]: DocumentUrl };
  ein: string;
  insuranceExpiration: string;
  insuranceAmount: number;
  bondAmount: number;
  accountOwner: string;
  leadAcqTime: string;
  accountCreationTime: number;
  lastSignInTime: number;
  projectView: number;
  projectPost: number;
  projectBids: number;
  birthday: number;
  timezone: number;
  gender: 'M' | 'F' | 'NB' | '';
  salutation: 'Mr.' | 'Ms.' | 'Mrs.' | 'Dr.' | '';
  subscriptionStartDate: number;
  subscriptionEndDate: number;
  lastLogin: number;
  subscriptionStatus: SubscriptionStatus;
  lastViewedProject: number;
  lastUpdated: number;
  admin: boolean;
  paid: boolean;
  comp: boolean;
  id: string;
  deviceTokens: string[];
  forteCustomerToken: string;
  fortePayMethodToken: string;
  paymentTokens: { [key: string]: CardPaymethodToken };
}

export const BlankUser: UserData = {
  id: '',
  lastUpdated: 0,
  fullName: '',
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  country: 'USA',
  referralLink: '',
  profileLink: '',
  referrer: '',
  phoneNum: '',
  phoneNumTwo: '',
  faxNum: '',
  companyPhoneNum: '',
  companyName: '',
  companyWebAddr: '',
  officeHours: '',
  mailAddr: '',
  billingAddress: BlankStreetAddress,
  linkedin: '',
  facebook: '',
  twitter: '',
  timezone: 0,
  lastLogin: 0,
  role: 'sub',
  language: 'en',
  trades: [],
  preferredWork: [],
  bilingual: false,
  notes: '',
  avatar: {
    original: '',
    processed: '',
    hash: '',
    height: 0,
    width: 0
  },
  logo: {
    original: '',
    processed: '',
    hash: '',
    height: 0,
    width: 0
  },
  companyDocs: {},
  ein: '',
  insuranceExpiration: '',
  salutation: '',
  insuranceAmount: 0,
  gender: '',
  accountOwner: '',
  leadAcqTime: '',
  accountCreationTime: 0,
  lastSignInTime: 0,
  birthday: 0,
  bondAmount: 0,
  projectView: 0,
  projectPost: 0,
  projectBids: 0,
  subscriptionStartDate: 0,
  subscriptionEndDate: 0,
  subscriptionStatus: SubscriptionStatus.FreeTrial,
  lastViewedProject: 0,
  admin: false,
  paid: false,
  comp: false,
  deviceTokens: [],
  forteCustomerToken: '',
  fortePayMethodToken: '',
  paymentTokens: {}
};

export interface InternalUserData {
  lastContacted: number;
  contactPreference: 'email' | 'phone' | 'mail' | 'other' | '';
  notes: string;
  loginCount: number;
}

export const BlankInternalUserData: InternalUserData = {
  lastContacted: 0,
  contactPreference: '',
  notes: '',
  loginCount: 0
};

/**
 * FullUserData is being deprecated but is currently exported as the same type
 * as UserData for backwards compatibility
 */
export type FullUserData = UserData;
