/**
 * Contains currency class & function to format currency value with
 * commas
 * @packageDocumentation
 */
/**
 * Currency class
 * creates a member val str on construction that has
 * the string form of the passed value
 */
export class Currency {
  private val: number;
  private str: string;
  constructor(val: number) {
    this.val = val;
    this.str = val.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }
}

export const FormatCurrencyValue: (value: number, prefix?: string) => string = (
  value,
  prefix = '$'
) => {
  const stringifiedValue = value.toString();
  const splitValue = stringifiedValue.split('.');
  if (splitValue.length === 0) {
    return '$0';
  }

  const dollarVal = splitValue[0];
  const separatedValue = [];
  let j = dollarVal.length;
  for (let i = dollarVal.length - 3; i > -dollarVal.length; i -= 3) {
    separatedValue.unshift(dollarVal.substring(i, j));
    j -= 3;
  }

  if (splitValue.length === 2) {
    return (
      prefix +
      separatedValue.filter((word) => word.length > 0).join(',') +
      '.' +
      splitValue[1]
    );
  }

  return prefix + separatedValue.filter((word) => word.length > 0).join(',');
};
