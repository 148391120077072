/**
 * Contains public profile, post, and review type definitions
 * @packageDocumentation
 */
import { Translations } from './Chat';
import { PhotoAlbum, ConstructionType, PhotoUrl } from './Project';
import { Trade } from './Trade';
import { StreetAddress, BlankStreetAddress } from './User';

export interface ProfilePost {
  id: string;
  postType: 'project' | 'tools' | 'other' | '';
  title: Translations<string>;
  description: Translations<string>;
  photos: PhotoAlbum;
  coverPhoto: string;
  tags: string[];
  lastUpdated: number;
  createdAt: number;
  public: boolean;
}

/**
 * ProfileProject
 * startDate & endDate are for easy entry
 * durationDays is in days (calced from start and end date)
 * durationHours is so they can add or remove hours (for e.g. 1 day proj they can say it was 6 hours, for 3 days they can say 3 days and 2 hrs)
 * display days if days > 1, or if days > 0 & hours > 0
 * display hours if > 0
 */
export interface ProfileProject extends ProfilePost {
  postType: 'project';
  trades: Trade[];
  status: 'In Progress' | 'Completed' | 'Open';
  projectType: ConstructionType;
  budget: number;
  durationDays: number;
  durationHours: number;
  startDate: number;
  endDate: number;
  showcase: boolean;
  address: StreetAddress;
  location: {
    latitude: number;
    longitude: number;
  };
}

export const DefaultProfilePost: ProfilePost = {
  id: '',
  postType: '',
  title: {
    en: '',
    es: ''
  },
  description: {
    en: '',
    es: ''
  },
  photos: {},
  coverPhoto: '',
  tags: [],
  lastUpdated: 0,
  createdAt: 0,
  public: true
};

export const DefaultProfileProject: ProfileProject = {
  ...DefaultProfilePost,
  postType: 'project',
  trades: [],
  status: 'Completed',
  projectType: 'New Construction Residential',
  budget: 0,
  durationDays: 0,
  durationHours: 0,
  startDate: 0,
  endDate: 0,
  showcase: false,
  address: BlankStreetAddress,
  location: {
    latitude: 0,
    longitude: 0
  }
};

export interface ProfileReview {
  title: string;
  review: string;
  totalScore: number;
  ratings: { [key: string]: number };
  tags: string[];
  reviewerId: string;
  reviewerName: '';
  projectId?: string;
}

export const DefaultProfileReview: ProfileReview = {
  title: '',
  review: '',
  totalScore: 0,
  ratings: {},
  tags: [],
  reviewerId: '',
  reviewerName: ''
};

export interface PublicProfile {
  id: string;
  linkSuffix: string;
  redirectLink: string;
  name: string;
  company: string;
  logo: PhotoUrl;
  averageResponseTime: number;
  about: Translations<string>;
  languages: Translations<number>;
  trades: Trade[];
  projects: ProfileProject[];
  reviews: ProfileReview[];
  averageRating: number;
  badges: string[];
  workTypes: ConstructionType[];
  public: boolean;
  city: string;
  state: string;
  country: string;
}

export const BlankPublicProfile: PublicProfile = {
  id: '',
  linkSuffix: '',
  redirectLink: '',
  name: '',
  company: '',
  logo: {
    original: '',
    hash: '',
    processed: '',
    width: 0,
    height: 0
  },
  averageResponseTime: 0,
  averageRating: 0,
  about: {
    en: '',
    es: ''
  },
  languages: {
    en: 0,
    es: 0
  },
  reviews: [],
  trades: [],
  projects: [],
  badges: [],
  workTypes: [],
  public: false,
  city: '',
  state: '',
  country: 'USA'
};
