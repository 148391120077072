/**
 * Contains types & definitions for supported langs, photoUrls, market projects,
 * project cards, rooms, project statuses, construction types, and building conditions
 * @packageDocumentation
 */
import { Trade } from './Trade';
import { Translations } from './Chat';
export type SupportedLang = 'en' | 'es';
export const SupportedLangs: SupportedLang[] = ['en', 'es'];

export type SupportedUserLang = SupportedLang | SupportedLang[];

export interface PhotoUrl {
  hash: string;
  original: string;
  processed: string;
  width: number;
  height: number;
  tags?: string[];
}

export const BlankPhotoUrl: PhotoUrl = {
  original: '',
  processed: '',
  hash: '',
  height: 0,
  width: 0
};

export enum CardType {
  YOUTUBE,
  ROOM,
  IFRAME,
  CONTACT,
  PROJECT,
  BLANK
}

export interface FullYoutubeCard {
  type: CardType.YOUTUBE;
  url: string;
  title: Translations<string>;
}

export interface YoutubeCard {
  type: CardType.YOUTUBE;
  url: string;
  title: string;
}

export const DefaultYoutubeCard: YoutubeCard = {
  type: CardType.YOUTUBE,
  url: '',
  title: 'Video Walkthrough'
};

export type DisplayYoutubeCard = YoutubeCard;

export interface FullRoomCard {
  type: CardType.ROOM;
  height: number;
  area: number;
  description: Translations<string>;
  name: Translations<string>;
  coverPhoto: PhotoHash;
  photos: PhotoHash[];
}

export const DefaultFullYoutubeCard: FullYoutubeCard = {
  type: CardType.YOUTUBE,
  url: '',
  title: { en: '', es: '' }
};

export const DefaultFullRoomCard: FullRoomCard = {
  type: CardType.ROOM,
  height: 0,
  area: 0,
  description: { en: '', es: '' },
  name: { en: '', es: '' },
  coverPhoto: '',
  photos: []
};

export interface RoomCard {
  type: CardType.ROOM;
  height: number;
  area: number;
  description: string;
  name: string;
  coverPhoto: PhotoHash;
  photos: PhotoHash[];
}

export const DefaultRoomCard: RoomCard = {
  type: CardType.ROOM,
  height: 0,
  area: 0,
  description: '',
  name: '',
  coverPhoto: '',
  photos: []
};

export type DisplayRoomCard = RoomCard;

export interface IframeCard {
  type: CardType.IFRAME;
  url: string;
}

export const DefaultIframeCard: IframeCard = {
  type: CardType.IFRAME,
  url: ''
};

export interface ContactCard {
  type: CardType.CONTACT;
  phoneNum: string;
  email: string;
  name: string;
  company: string;
}

export const DefaultContactCard: ContactCard = {
  type: CardType.CONTACT,
  phoneNum: '',
  email: '',
  name: '',
  company: ''
};

export interface CoverCard {
  type: CardType.PROJECT;
  name: string;
  description: string;
}

export const DefaultCoverCard: CoverCard = {
  type: CardType.PROJECT,
  name: '',
  description: ''
};

export interface BlankCard {
  type: CardType.BLANK;
}

export const DefaultBlankCard: BlankCard = {
  type: CardType.BLANK
};

export type ProjCard =
  | RoomCard
  | YoutubeCard
  | IframeCard
  | ContactCard
  | CoverCard
  | BlankCard;

export type DisplayProjectCard =
  | DisplayRoomCard
  | DisplayYoutubeCard
  | IframeCard;

export type FullProjectCard = FullRoomCard | FullYoutubeCard | IframeCard;

export interface PhotoAlbum {
  [key: string]: PhotoUrl;
}

export type PhotoHash = keyof PhotoAlbum & string;

export interface ProjectEdit {
  uid: string;
  uname: string;
  updateTime: number;
}

// UN/LOCODE for short ids?
export interface ProjectMetadata {
  id: string;
  shortId: string;
  firebaseId: string;
  created: string;
  lastUpdated: number;
  ownerId: string;
  additionalOwners: string[];
  subcontractors: string[];
  propertyOwner: string;
  ownerName: string;
  shareLink: string;
  estimate: number;
  successFee: number;
  company: string;
  contact: ContactCard;
  public: boolean;
  approved: boolean;
  awarded: boolean;
  pdfDownload: boolean;
  rfp: boolean;
  rfpStart: number;
  rfpDeadline: number;
  awardValue: number;
  coverPhoto: PhotoHash;
  photos: PhotoAlbum;
  trades: Trade[];
  status: ProjectStatus;
  projectType: ConstructionType;
  condition: BuildingCondition;
  createdBy: string;
  edits: ProjectEdit[];
  address: string;
  city: string;
  state: string;
  streetAddr: string;
  zipCode: string;
  country: string;
  publishDate?: number;
  startDate?: number;
  endDate: number;
  location: {
    latitude: number;
    longitude: number;
  };
  chatPreroll: {
    initial: string;
    awarded: string;
  };
}

export const DefaultProjectMetadata: ProjectMetadata = {
  shortId: '',
  id: '',
  firebaseId: '',
  created: '',
  lastUpdated: 1559252406785,
  ownerId: '',
  additionalOwners: [],
  subcontractors: [],
  ownerName: '',
  createdBy: '',
  shareLink: '',
  edits: [],
  propertyOwner: '',
  estimate: 0,
  successFee: 0,
  contact: DefaultContactCard,
  company: '',
  public: false,
  approved: false,
  awarded: false,
  rfp: false,
  rfpDeadline: 0,
  rfpStart: 0,
  pdfDownload: false,
  awardValue: 0,
  coverPhoto: '',
  photos: {},
  trades: [],
  status: 'Draft',
  projectType: 'New Construction Residential',
  condition: 'Average',
  address: '',
  city: '',
  state: '',
  streetAddr: '',
  zipCode: '',
  country: 'USA',
  endDate: 0,
  location: {
    latitude: 37.4267861,
    longitude: -122.0806032
  },
  chatPreroll: {
    initial: '',
    awarded: ''
  }
};

export type ProjectLangs = Record<SupportedLang, ProjCard[]>;

export interface FullProject extends ProjectMetadata {
  title: Translations<string>;
  description: Translations<string>;
  projects: ProjectLangs;
  cards: FullProjectCard[];
}

export const DefaultFullProject: FullProject = {
  ...DefaultProjectMetadata,
  projects: {
    en: [DefaultCoverCard, DefaultBlankCard],
    es: [DefaultCoverCard, DefaultBlankCard]
  },
  title: {
    en: '',
    es: ''
  },
  description: {
    en: '',
    es: ''
  },
  cards: []
};

export interface DisplayProject extends ProjectMetadata {
  description: string;
  title: string;
  cards: DisplayProjectCard[];
  translatedTitle: Translations<string>;
}

export const DefaultDisplayProject: DisplayProject = {
  ...DefaultProjectMetadata,
  description: '',
  title: '',
  translatedTitle: {
    en: '',
    es: ''
  },
  cards: []
};

export function MakeDisplayProject(
  proj: FullProject,
  lang: SupportedLang
): DisplayProject {
  return {
    ...proj,
    title: proj.title[lang],
    translatedTitle: proj.title,
    description: proj.description[lang],
    cards: proj.cards
      ? proj.cards.map((card) => MakeDisplayProjectCard(card, lang))
      : []
  };
}

export function MakeDisplayProjectCard(
  card: FullProjectCard,
  lang: SupportedLang
): DisplayProjectCard {
  switch (card.type) {
    case CardType.YOUTUBE:
      return {
        ...card,
        title: card.title[lang]
      };
    case CardType.ROOM:
      return {
        ...card,
        name: card.name[lang],
        description: card.description[lang]
      };
    default:
      return card;
  }
}

export const BuildingConditions = [
  'Excellent',
  'Very Good',
  'Good',
  'Average',
  'Fair',
  'Poor',
  'Dilapidated'
] as const;

type AllBuildingConditions = typeof BuildingConditions;
export type BuildingCondition = AllBuildingConditions[number];

export const ProjectStatuses = [
  'Draft',
  'Reviewed',
  'Suspended',
  'Flagged',
  'Scheduled',
  'Published',
  'Closed',
  'Other',
  'Awarded',
  'Finished',
  'In Progress',
  'Archived'
] as const;

type AllProjectStatuses = typeof ProjectStatuses;
export type ProjectStatus = AllProjectStatuses[number];

export const ConstructionTypes = [
  'New Construction Residential',
  'New Construction Commercial Residential',
  'New Construction Commercial',
  'Remodel',
  'Retrofit',
  'Renovation',
  'Restoration',
  'Maintenance'
] as const;

type AllConstructionTypes = typeof ConstructionTypes;
export type ConstructionType = AllConstructionTypes[number];

export type ShortCType = 'NB' | 'NC' | 'NR' | 'RM' | 'RT' | 'RN' | 'RS' | 'MT';
export const ShortCTypeMap: Record<ConstructionType, ShortCType> = {
  'New Construction Residential': 'NB',
  'New Construction Commercial Residential': 'NR',
  'New Construction Commercial': 'NC',
  Remodel: 'RM',
  Retrofit: 'RT',
  Renovation: 'RN',
  Restoration: 'RS',
  Maintenance: 'MT'
};
