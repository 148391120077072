/**
 * Contains the definition of chat and message types
 * @packageDocumentation
 */
import { SupportedLang, PhotoUrl, BlankPhotoUrl } from './Project';

/**
 * not sure if this is really necessary but it's nice for
 * house-keeping at the least & it's not hard to add to
 * request is for the first message sent for a project chat
 * active is for an accepted request (changed after reply)
 * inactive is for a chat that was active that has no activity for > 1 week
 * denied are if denials are setup at any point, refused request (for filtering & cleanup)
 * archived are for chats that have been archived so they can be loaded and searched if required
 * but dont surface normally
 */
export type ChatType =
  | 'request'
  | 'active'
  | 'inactive'
  | 'denied'
  | 'archived';

/**
 * chat state describes the state of the chat, which is more for display puporses
 * than chat type
 * new is at the beginning
 * plansRequested digital or paper after sub has requested
 * the first requires the email to be confirmed by sub before updating
 * taht email is then shared with gc for sharing the plans
 * paper leads to gc confirming the location for pickup
 * pickupLocationConfirmed -> noti loop making sure the sub got them
 * plansSent used for both to indicate they've been picked up or sent
 * plansReviewed after confirmation the sub recv & reviewed them
 * if not received, shoots em back to plansRequestedDigital & re-confirm email
 * (no analog for paper plans, doesn't go to plansSent until they picked them up)
 * plansReviewed leads to either sub or gc putting in their availability for site request
 * meetingRequested after one has put in & sent message asking about availability
 * rescheduleMeet if all times are denied, the denier suggests one (1) new time to meet
 * this'll be a loop
 * meetingConfirmed after the receiver confirms a time & date that works for them
 * save this date to db
 * this leads to a short loop that occurs with reminder notifications until the date & time
 * of the meet
 * if at any time they cancel, it goes to rescheduleMeet
 * postMeeting lets both sub & gc review the meeting (good or bad) and if contact will continue
 * chatEnded if bad and no, chatType -> archived
 * preBid if it went well, allows free chat & submittal of bid
 * postBid after bid submitted (can go back to preBid if straight up denied, or stay in this state
 * while negotiation happens)
 * bidAccepted after it's been accepted by gc
 * locationRequest if gc sends 'where are you'
 * enroute is response state (allows for going back to locationRequest if they don't show up)
 * inProgress while work is in progress
 * allows for punchlist button, quick photo send button, update request from gc
 * requestUpdate will encompass a lot but really will require either a photo update or punchlist
 * update from sub
 * workReview after sub indicates completion
 * workFinished after gc has reviewed and confirmed work is to standards/request/etc.
 */
export type CState =
  | 'new'
  | 'plansRequestedDigital'
  | 'plansRequestedPaper'
  | 'pickupLocationConfirmed'
  | 'plansSent'
  | 'plansReviewed'
  | 'meetRequested'
  | 'rescheduleMeet'
  | 'meetConfirmed'
  | 'postMeeting'
  | 'chatEnded'
  | 'preBid'
  | 'postBid'
  | 'bidAccepted'
  | 'locationRequest'
  | 'enroute'
  | 'inProgress'
  | 'requestUpdate'
  | 'workReview'
  | 'workFinished'
  | 'notificationLoop';

export type Translations<T> = Record<SupportedLang, T>;

export const BlankStringTranslation: Translations<string> = {
  en: '',
  es: ''
};

export interface LatestMessage {
  sender: string;
  original: string;
  translations: Translations<string>;
}

export const BlankLatestMessage: LatestMessage = {
  sender: '',
  original: '',
  translations: BlankStringTranslation
};

/**
 * Chat interface type
 * photo is required now for background (if blank string it'll just be normal anyway)
 * latestMessage is to make it easier to display chatList
 * projectId is the associated project for this chat
 * userIds & userNames are arrays in the same order of the firebase ids & names of users in chat
 * dont mutate them, should never remove either just create new chat. add is ok if it's kept in order
 * can update by updating userIds and userNames making sure they're in the same order
 * lastUpdated from firebase server
 * location will come from the associated project
 * askManyBuildPings tracks how many times they've hit the button, using lastManyBuildPing to track
 * when it was last pinged
 * seen usage:
 *  keys are userIds
 *  if seen = 0 it is not seen
 *  if seen = 1 the user is the sender
 *  if seen > 1, the number is the Date when they saw it
 * projectId is the firebase ID of the project for tying back into it
 * projectTitle & projectShortId are copied from the project on chat creation to allow for display
 * without pulling in the project as well (allows for deleted/non-rfp projects to still be partially
 * displayed in chats)
 * photo is the same (uses project coverphoto)
 */
export interface Chat {
  id: string;
  type: ChatType;
  state: CState;
  messages: Message[];
  latestMessage: LatestMessage;
  seen: {
    [key: string]: number;
  };
  photo: PhotoUrl;
  projectId: string;
  projectTitle: Translations<string>;
  projectShortId: string;
  userIds: string[];
  users: {
    [key: string]: ChatUser;
  };
  userNames: string[];
  createdAt: number;
  lastUpdated: number;
  location?: {
    longitude: number;
    latitude: number;
  };
  askManyBuildPings: number;
  lastManyBuildPing: number;
}

export const BlankChat: Chat = {
  id: '',
  type: 'request',
  state: 'new',
  messages: [],
  latestMessage: BlankLatestMessage,
  seen: {},
  photo: BlankPhotoUrl,
  projectId: '',
  projectTitle: BlankStringTranslation,
  projectShortId: '',
  userIds: [],
  users: {},
  userNames: [],
  createdAt: 0,
  lastUpdated: 0,
  askManyBuildPings: 0,
  lastManyBuildPing: 0
};

export interface ChatUser {
  name: string;
  uid: string;
  projectOwner: boolean;
  seen: number;
  avatar?: string;
  language: SupportedLang;
  subscribedNotifications: boolean;
}

export const BlankChatUser: ChatUser = {
  name: '',
  uid: '',
  projectOwner: false,
  seen: 0,
  language: 'en',
  subscribedNotifications: true
};

interface Reply {
  title: string;
  value: string;
  messageId?: any;
}

interface QuickReplies {
  type: 'radio' | 'checkbox';
  values: Reply[];
  keepIt?: boolean;
}

/**
 * Message type for chats
 * createdAt date in number (milliseconds since 1970)
 * state is for searching messages by state, only on message if it causes a state transition & indicates the new state
 */
export interface Message {
  id: string;
  chatId: string;
  state?: CState;
  user: {
    id: string;
    name: string;
    avatar?: string;
  };
  createdAt: number;
  original: string;
  sent?: boolean;
  system?: boolean;
  admin?: boolean;
  translations: Translations<string>;
  image?: PhotoUrl;
  file?: string;
  video?: string;
  // quick replies (mirrors definition from gifted-chat)
  // need to either have them pre-translated (ez) or translate automatically (hard)
  // probably can't let people customize them if they need translations for now
  replies?: Translations<QuickReplies>;
  // this is for sending a location, not specifically for sending an on my way message, omw msg doesn't send location currently
  location?: LocationAttachment;
  mention?: string;
}

export interface LocationAttachment {
  long: number;
  lat: number;
}
